<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader class="bg-secondary">
            Faucet 
          </CCardHeader>
          <CCardBody  class="bg-info">
            <h5 class='text-white'>Your Faucet Money : {{faucet}}</h5>


            <CForm>
            <CRow>
              <CCol sm="12">
                <CCard>
                  
                  <CTabs>
                  <CTab title="Manual" active>
                  <CCardBody>
                    <CRow>
                      <CCol sm="12">
                        <div class="form-group">
                        <label> Coin</label>
                        <select v-model="coin" placeholder='Pilih Coin' class="form-control">
                          <option value="3" selected>USDT</option>
                          <option value="1">Bitcoin</option>
                        </select>
                        </div>
                      </CCol>  
                      <CCol size="12"> 
                      <div class="form-group">
                      <label>Bet Amount</label>   
                      <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" v-on:keyup="setRollOver" v-model='bet_amount' aria-describedby="basic-addon2">
                        <div class="input-group-append" style="cursor: pointer;" @click="setHalf()">
                          <span class="input-group-text"  id="basic-addon2">1/2</span>
                        </div>
                        <div class="input-group-append" style="cursor: pointer;" @click="setDouble()">
                          <span class="input-group-text" id="basic-addon2">2x</span>
                        </div>
                      </div>
                      </div>            
                      
                      </CCol>
                    </CRow>
                    <CRow>
                    <div class='col-6'>           
                      <CInput
                        type="text"
                        v-model="profit_on_win"
                        label="Profit on Win"
                        placeholder="Profit on Win"
                        autocomplete="text"
                      >
                        <template #append-content>
                          <img
                  :src="require('../assets/images/usdt.png')"
                  style="width: 16px;margin : 0 auto"
               />
                        </template>
                      </CInput>
                    </div>
                    <div class='col-6'>
                      <CInput
                        type="text"
                        v-model="roll_over"
                        label="Roll Over"
                        placeholder="Roll Over"
                        autocomplete="text"
                      >
                        <template #append-content><CIcon name="cil-loop"/></template>
                      </CInput>
                    </div>
                    <div class='col-6'>
                       <CInput
                        type="text"
                        v-model="multiplier"
                        label="Multiplier"
                        placeholder="Multiplier"
                        autocomplete="text"
                        append="X"
                      >
                        <template #append-content></template>
                      </CInput>
                    </div>
                    <div class='col-6'>
                      <CInput
                        v-model="win_chance"
                        label="Win Chance"
                        placeholder="Win Chance"
                        append="%"
                      >
                        <template #append-content></template>
                      </CInput>
                    </div>
                    <div class='col-12'>
                <!--           <input type="range" 
                v-c-tooltip.hover.click="'I am a tooltip!'" v-model="roll_over" @input="setRollOver" class="form-range form-control" min="2" max="98" step="0.5" id="customRange3" style="border-color:#e55353!important; color:#e55353 !important"> -->
                    </div>
                    <div class="col-12">
                          <range-slider
                            @input="setRollOver"
                            class="slider form-control"
                            min="2"
                            max="98"
                            step="0.5"
                            v-model="roll_over">
                          </range-slider>
                          <!-- <div class="tooltip-box">
                            <slot />
                            <div
                              class="tooltip"
                            >
                              <span
                                class="text"
                              >{{ roll_result }}</span>
                            </div>
                          </div> -->
                    </div>
                    </CRow>

                    
                  </CCardBody>
                  </CTab>

                  <CTab title="Auto" >
                  <CCardBody>               
                    <h1>COMING SOON</h1>


                  
                  </CCardBody>
                  </CTab>
                  <CTab title="Claim" >
                  <CCardBody>               
                    <h1>COMING SOON</h1>


                  
                  </CCardBody>
                  </CTab>
                
                </CTabs>
                  <CCardFooter>
                    <CButton type="button" @click="setRollDice()" block color="warning"><CIcon name="cil-check-circle"/> ROLL DICE</CButton>
                    <!-- <CButton type="reset" size="sm" color="danger"><CIcon name="cil-ban"/> Reset</CButton> -->
                  </CCardFooter>
                </CCard>
              </CCol>

              
            </CRow>          
                <!-- <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/suratmasuk">Batal</CButton>
                </div> -->
            </CForm>

            </CTab>
            </CTabs>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader class="bg-dark">
            <span class="text-white">Live Stats</span>
          </CCardHeader>
          <CCardBody  class="bg-white">
            <CRow>
              <div class='col-6'><strong>Wagered</strong><br><span class='text-danger'>{{waggered }}</span></div>
              <div class='col-6'><strong>Profits</strong><br><span class='text-success'>{{profits }}</span></div>
            </CRow>
            <hr>
            <CRow>
              <div class='col-6'><strong>Wins</strong>: <span class='text-danger'>{{wins }}</span></div>
              <div class='col-6'><strong>Loses</strong>: <span class='text-success'>{{losses }}</span></div>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CModal title="Coming Soon" :show.sync="myCS" size="sm">
      Coming Soon...
    </CModal>
    <CModal title="Error" :show.sync="myErrorFaucet" size="sm">
      Error! Your Faucet Money is empty please Claim or deposit first.
    </CModal>
    <CModal title="Result" :show.sync="myRes" class='modal-dialog-center' size="sm">
      <center>
        <img
          :src="getImgUrl('win.png')"
          style="width: 110px; margin: 10 auto;clear:both"
        />
        <h1>{{result}} : You {{status}}</h1>
      </center>
    </CModal>
  </div>
</template>
<style scoped>
.tooltip-box { 
  position: relative;
  display: inline-block;
}

.tooltip-box:hover .tooltip{
  opacity: 1;
}

.tooltip { 
  color: #ffffff;
  text-align: center;
  padding: 5px 0;
  border-radius: 2px;
  
  width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;

  opacity: 0;
  transition: opacity 1s;

  position: absolute;
  z-index: 1;

  background: #a782e8;
}

.text::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #a782e8 transparent transparent transparent;
}
.slider {
  /* overwrite slider styles */
  width: 100%;
}
.modal-dialog-center {
    margin-top: 50% !important;
}
</style>
<script>

import axios from "axios";
import RangeSlider from 'vue-range-slider'
// you probably need to import built-in style
import 'vue-range-slider/dist/vue-range-slider.css'
let user = JSON.parse(localStorage.getItem('user'));
export default {
  name: 'Tabs',
  components: {
    RangeSlider,
  },
  data () {
    return {
      tabs: [
        'Profil',
        'Visi Misi',
        'Tupoksi'
      ],
      myRes : false,
      status : "Lose",
      pic:"win.png",
      // statusimage : "../assets/images/usdt.png",
      result : 0,
      coins : [],
      coin : "3",
      wins : 0,
      losses : 0,
      waggered : "0.0000",
      profits : "0.0000",
      myCS : false,
      result_roll: "30.00",
      win_chance: "50.00",
      profit_on_win: "0.00000020",
      bet_amount: "0.00000010",
      roll_over: "50.00",
      multiplier: "2.0000",
      depo : "0",
      faucet: "0",
      myErrorFaucet:false,
      // histories:JSON.parse(this.$store.state.faucets),
      user : JSON.parse(this.$store.state.users),
      activeTab: 1
    }
  },
  beforeMount(){
    let us = JSON.parse(this.$store.state.users);
    // alert(us.wallet_faucet);
    this.faucet = us.wallet_faucet ;
    // alert(this.faucet);
    // alert(this.histories);
    // this.waggered = this.histories.waggered ?? "0.00000000";
    // this.profits = this.histories.profits ?? "0.00000000";
    // this.losses = this.histories.losses ?? "0";
    // this.wins = this.histories.wins ?? "0";
    
    
  },
  created () {
      // this.updateData();
      this.timer = setInterval(this.updateData, 60000);
  },
  methods: {
    updateData: function (event) {
      // Simple POST request with a JSON body using axios
      const user = {
        username: this.user.id,
        wallet_faucet: this.faucet,
      };
      // alert(localStorage.getItem("jwt"));
      axios
          .post(
            process.env.VUE_APP_BASE_URL+"updatefaucet",
            user
          )
          .then((response) => {
            // console.log(response);
            // alert(response.data);
            // this.tabs = response.data;/
            console.log(response.data);
          });
      
    },
    getImgUrl() {
      return require('../assets/images/'+this.pic)
    },
    setRollOver(){
        var ro = parseFloat(this.roll_over);
        var wc = 100 - ro;
        var m = parseFloat(this.multiplier);
        this.win_chance = String(wc);
        if(ro<51){
          var mfactor = 3 - ((wc  / 100)*2);
        }
        else if(ro>50 && ro<91){
          var mfactor = 2 + ((ro - 50) * 2 / 10);
        }
        else{
          var mfactor = 10 + ((ro - 90) * 5);   
        }
            this.multiplier = String(mfactor); 
        var bet = this.bet_amount ?? "0";
        var profit = (parseFloat(bet) * parseFloat(this.multiplier));
        this.profit_on_win = this.toFixed(profit);
        
    },
    toFixed: function(x) {
      if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10,e-1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
      } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10,e);
            x += (new Array(e+1)).join('0');
        }
      }
      return x;
    },
    setRollDice: function(){
      var wf = parseFloat(this.faucet);
      if(wf>0){
        var ro = parseFloat(this.roll_over);
        var wc = (100 - ro)/100;
        var wc2 = (100 - ro);
        var ran = Math.floor((Math.random() < wc) * 100) + 1;
        var ran2 = Math.floor((Math.random()) * 100) + 1;
        this.result = ran2.toString();
        var wg = 0;
        var prf = 0;
        var lss = 0;
        var wns = 0;
        if(ran2<wc2){
          this.pic = "win.png";
          this.status = "Win";

          // this.histories.profits = +profits + (this.toFixed(this.profit_on_win) - this.toFixed(this.bet_amount) );
          // this.histories.wins = +wins + 1;
          this.faucet = this.faucet + (this.toFixed(this.profit_on_win) - this.toFixed(this.bet_amount) );
          this.user.wallet_faucet = this.faucet;
          this.$store.commit('setUsers', JSON.stringify(this.user));
          // this.faucet=response.data.wallet;
          // this.$store.commit('setFaucet', JSON.stringify(this.histories));
        }
        else{
          this.pic = "lose.png";
          this.status = "Lose";
          // this.histories.waggered = +waggered - +this.bet_amount;
          // this.histories.losses = +losses + 1;
          this.faucet = this.faucet - this.toFixed(this.bet_amount);
          this.user.wallet_faucet = this.faucet;
          this.$store.commit('setUsers', JSON.stringify(this.user));
          // this.$store.commit('setFaucet', JSON.stringify(this.histories));
        }
        this.myRes = true;
        
      
  
      }
      else{
        this.myErrorFaucet= true;
      }
      
    },
    setDouble: function() {
      // if(val>0){
        var betamount = parseFloat(this.bet_amount);
        betamount = betamount * 2;
        this.bet_amount = String(betamount);
      // }
    },
    setHalf: function() {
      // if(val>0){
        var betamount = parseFloat(this.bet_amount);
        betamount = betamount / 2;
        this.bet_amount = String(betamount);
      // }
    },
  }
}
</script>
